import { ART } from "../shared/art";

export const initialFeedback = {
	name: '',
	phoneNum: '',
	email: '',
	message: '',
};

export const Art = (state = ART, action) => {
	return state;
};
