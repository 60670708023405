const creaturesPortfolio = [
	{
		title: "Great Bear",
		body:
			"An Iroquois legend. Also called the Nyah Gwaheh, the bear was a dangerous monstrosity. It was hunted by three brothers until it fled into the sky, to become the Great Bear constellation, Ursa Major.",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237200/Haley%20Ellis/great_bear_fe4xy1.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio Great Bear",
	},
	{
		title: "Yelbeghen",
		body: "Part of Turkish mythology, this giant ogre rode astride a hundred-horned ox, and desired only to consume the sun.",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237718/Haley%20Ellis/yelbeghen_elk5uh.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio Yelbeghen",
	},
	{
		title: "Long Migration",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237909/Haley%20Ellis/long_migration_e8jtp2.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio Long Migration",
	},
	{
		title: "Prehistoric Bird",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237808/Haley%20Ellis/prehistoric_birds_dh9jql.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio Prehistoric Bird",
	},
	{
		title: "Altamaha-ha",
		body: "A cryptid in the state of Georgia, it lives in the Altamaha River.",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617236966/Haley%20Ellis/Altamaha-ha_ikrj6y.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio Altamaha-ha",
	},
	{
		title: "White River Monster",
		body: "This cryptid became so beloved in White River, Arkansas, that the White River Monster conservation act was created to protect it.",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237744/Haley%20Ellis/White_River_Monster_zb0z8w.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio White River Monster",
	},
	{
		title: "Hela",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237970/Haley%20Ellis/Hela_pcjbsk.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio Hela",
	},
	{
		title: "Snektopus",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237782/Haley%20Ellis/snektopus_zilmgb.png",
		alt: "Haley Ellis Illustration Creatures Portfolio Snektopus",
	},
	{
		title: "Frorpion",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617236876/Haley%20Ellis/frorpion_p5j4v6.png",
		alt: "Haley Ellis Illustration Creatures Portfolio Frorpion",
	},
	{
		title: "Monstrosity",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237867/Haley%20Ellis/monstrosity_lqq8ur.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio Monstrosity",
	},
	{
		title: "Aye-Aye in the Fog",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617236993/Haley%20Ellis/Aye-aye_in_the_fog_kqdvja.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio Aye-Aye in the Fog",
	},
	{
		title: "Polar Monster",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237827/Haley%20Ellis/polar_monster_s0xqop.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio Polar Monster",
	},
	{
		title: "Martian Creature",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237888/Haley%20Ellis/martian_creature_cmaljn.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio Martian Creature",
	},
	{
		title: "Bake Kujira",
		body: "A giant phantasmal whale skeleton, part of Japanese folklore.",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237022/Haley%20Ellis/Bake_Kujira_oecajk.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio Bake Kujira",
	},
	{
		title: "Inuit Myths",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237921/Haley%20Ellis/Inuit_myths_gsuc0w.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio Inuit Myths",
	},
	{
		title: "E Gui",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237352/Haley%20Ellis/E_Gui_kuwcm5.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio E Gui",
	},
	{
		title: "Cheeronear",
		body: "An aboriginal dogman who hunts the Australian desert for stray children.",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237101/Haley%20Ellis/Cheeronear_m4wda5.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio Cheeronear",
	},
	{
		title: "Wendigo",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237760/Haley%20Ellis/Wendigo_ovjjfh.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio Wendigo",
	},
	{
		title: "Hippogriff",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237952/Haley%20Ellis/Hippogriff_bbka0p.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio Hippogriff",
	},
	{
		title: "Old Gods",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237845/Haley%20Ellis/old_gods_jdcfu1.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio Old Gods",
	},
	{
		title: "Cosmic",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237142/Haley%20Ellis/cosmic_jsrhwe.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio Cosmic",
	},
	{
		title: "Gulper Eel + Common Toad Chimera",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237233/Haley%20Ellis/gulper_eel_common_toad_optifx.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio Gulper Eel + Common Toad Chimera",
	},
	{
		title: "Barn Owl + Tortoise",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237065/Haley%20Ellis/barnowltortoise_k9nqdx.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio Barn Owl + Tortoise",
	},
	{
		title: "Headless Horseman",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617239248/Haley%20Ellis/headless_horseman_ca3i89.jpg",
		alt: "Haley Ellis Illustration Creatures Portfolio Headless Horseman",
	},
]

const naturePortfolio = [
	{
		title: "Snow Leopard",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1618061444/Haley%20Ellis/Snow_Leopard_szrgow.jpg",
		alt: "Haley Ellis Illustration Nature Portfolio Snow Leopard",
	},
	{
		title: "Sandhill Cranes",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1618061444/Haley%20Ellis/Sandhill_Cranes_ntriae.jpg",
		alt: "Haley Ellis Illustration Nature Portfolio Sandhill Cranes",
	},
	{
		title: "Great Gray Owl",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1618061444/Haley%20Ellis/Great_Gray_Owl_vsiwb6.jpg",
		alt: "Haley Ellis Illustration Nature Portfolio Great Gray Owl",
	},
	{
		title: "Pelicans",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237547/Haley%20Ellis/Pelicans_wbgykk.jpg",
		alt: "Haley Ellis Illustration Nature Portfolio Pelicans",
	},
	{
		title: "The Hawk",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617236930/Haley%20Ellis/theHawk_d0iavh.png",
		alt: "Haley Ellis Illustration Nature Portfolio The Hawk",
	},
	{
		title: "A Careful Plan",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237260/Haley%20Ellis/a_careful_plan_fbym7r.jpg",
		alt: "Haley Ellis Illustration Nature Portfolio A Careful Plan",
	},
	{
		title: "Frilled Shark Study",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237312/Haley%20Ellis/frilled_shark_study_tl1ftt.jpg",
		alt: "Haley Ellis Illustration Nature Portfolio Frilled Shark Study",
	},
	{
		title: "The Toad",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237585/Haley%20Ellis/the_toad_qkz9xn.jpg",
		alt: "Haley Ellis Illustration Nature Portfolio The Toad",
	},
	{
		title: "The Woods",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617236915/Haley%20Ellis/thewoods_s3yszb.png",
		alt: "Haley Ellis Illustration Nature Portfolio The Woods",
	},
	{
		title: "Venus Flytraps",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237610/Haley%20Ellis/venus_flytraps_wvqe7v.jpg",
		alt: "Haley Ellis Illustration Nature Portfolio Venus Flytraps",
	},
	{
		title: "Jaybird",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237514/Haley%20Ellis/JayBird_gupmxt.jpg",
		alt: "Haley Ellis Illustration Nature Portfolio Jaybird",
	},
	{
		title: "Green Anole",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237427/Haley%20Ellis/green_anole_oxvuan.jpg",
		alt: "Haley Ellis Illustration Nature Portfolio Green Anole",
	},
	{
		title: "Grasshopper Mouse",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237382/Haley%20Ellis/Grasshopper_mouse_zqobkq.jpg",
		alt: "Haley Ellis Illustration Nature Portfolio Grasshopper Mouse",
	},
	{
		title: "Great Blue Heron",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237405/Haley%20Ellis/Greatblueheron_ldrnpt.jpg",
		alt: "Haley Ellis Illustration Nature Portfolio Great Blue Heron",
	},
	{
		title: "Hummingbirds",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237490/Haley%20Ellis/Hummingbirds_u7no73.jpg",
		alt: "Haley Ellis Illustration Nature Portfolio Hummingbirds",
	},
	{
		title: "Crows in the Park",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237280/Haley%20Ellis/Crows_in_the_park_onlhpb.jpg",
		alt: "Haley Ellis Illustration Nature Portfolio Crows in the Park",
	},
]

export const ART = {
	creaturesPortfolio,
	naturePortfolio,
	aboutImage: {
		title: "Hela",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237970/Haley%20Ellis/Hela_pcjbsk.jpg",
		alt: "Haley Ellis Illustration Portfolio Hela About Page",
	},
	commissionImage: {
		title: "The Woods",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617236915/Haley%20Ellis/thewoods_s3yszb.png",
		alt: "Haley Ellis Illustration Portfolio The Woods Commission Page",
	},
	contactImage: {
		title: "Frorpion",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617236876/Haley%20Ellis/frorpion_p5j4v6.png",
		alt: "Haley Ellis Illustration Portfolio Frorpion Contact Page",
	},
	homePictureSmall: {
		title: "Great Bear",
		body:
			"An Iroquois legend. Also called the Nyah Gwaheh, the bear was a dangerous monstrosity. It was hunted by three brothers until it fled into the sky, to become the Great Bear constellation, Ursa Major.",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617237200/Haley%20Ellis/great_bear_fe4xy1.jpg",
		alt: "Haley Ellis Illustration Portfolio Great Bear Home Page",
	},
	homePictureBig: {
		title: "The Hawk",
		body: " ",
		image: "https://res.cloudinary.com/csullivan/image/upload/v1617236930/Haley%20Ellis/theHawk_d0iavh.png",
		alt: "Haley Ellis Illustration Portfolio The Hawk Home Page",
	},
}
